import "./src/styles/fonts.css";
import "./src/styles/index.css";
import "./src/styles/react_dates_overrides.css";
import "./src/styles/tailwind.css";
import "./src/styles/typography.css";
import "./src/styles/utils.css";

require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`,
});

const onClientEntry = () => {
  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_TAG_MANAGER_ID}`;
  script.async = true;
  document.body.appendChild(script);
};

export { onClientEntry };
